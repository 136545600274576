import React from 'react'
// Material
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'

// Component
import Sidebar from 'components/Sidebar'
import NavBar from 'components/NavBar'
import Footer from 'components/Footer'

// Theme
import themeStyle from '../themeStyle'
import Fade from '@material-ui/core/Fade'

const theme = createMuiTheme(themeStyle)

const Layout = (props) => {
  return (
    <div style={{ height: '100vh' }}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Fade in={true} timeout={400}>
          <div>
            <NavBar />
            <Grid container direction="row" style={{ height: 'calc(100vh - 66px - 56px)' }}>
              <Sidebar />
              <Grid
                item
                style={{
                  width: 'calc(100% - 230px)',
                  height: 'calc(100vh - 66px - 56px)',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                  padding: '15px 24px',
                  background: '#f0f0f0',
                }}
              >
                {props.children}
              </Grid>
            </Grid>
            <Footer />
          </div>
        </Fade>
      </ThemeProvider>
    </div>
  )
}

export default Layout
