import { useIntl } from 'react-intl'
import SectionCard from 'components/SectionCard'
import CloseContactToolbar from 'components/CloseContact/CloseContactToolbar'
import CloseContactGrid from 'components/CloseContact/CloseContactGrid'
import useCloseContact from 'customHooks/useCloseContact'

const CloseContact = () => {
  const intl = useIntl()
  const [
    { preLoading, filters, intervalHeaders, csvData },
    search,
    cancel,
    exportCSV,
    toggleHeader,
    getIntervalDetail,
    isHeaderExpanded
  ] = useCloseContact()

  return (
    <>
      <SectionCard cardTitle={intl.formatMessage({ id: 'sidebar.closeContact' })}>
        <CloseContactToolbar
          filters={filters}
          loading={preLoading}
          csvData={csvData}
          onSearch={() => search()}
          onExportCSV={() => exportCSV()}
        />
        <CloseContactGrid
          loading={preLoading}
          dataHeaders={preLoading ? [] : intervalHeaders}
          getIntervalDetail={getIntervalDetail}
          isHeaderExpanded={isHeaderExpanded}
          onToggleHeader={(data) => toggleHeader(data.id)}
        />
      </SectionCard>
    </>
  )
}

export default CloseContact
