import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import { DETAIL_COLUMNS } from './config'

const CloseContactSubGrid = ({ parentId, getData }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    getData(parentId).then((response) => {
      setData(response)
      setLoading(false)
    })
  }, [])

  return (
    <div style={{ padding: '10px 0px 10px 50px' }}>
      <MaterialTable
        columns={DETAIL_COLUMNS}
        isLoading={loading}
        data={data}
        options={{
          filtering: false,
          grouping: false,
          search: false,
          toolbar: false,
          draggable: false,
          showFirstLastPageButtons: false,
          exportButton: false,
          showTitle: false,
          search: false,
          padding: 'default',
          actionsColumnIndex: -1,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 25],
          emptyRowsWhenPaging: false
        }}
        localization={{
          toolbar: {
            searchPlaceholder: 'Buscar',
            nRowsSelected: '{0} filas seleccionadas'
          },
          pagination: {
            labelRowsSelect: 'Filas',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsPerPage: 'Filas por página',
            firstTooltip: 'Primera página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Siguiente página',
            lastTooltip: 'Última página'
          },
          body: {
            emptyDataSourceMessage: 'No hay datos para visualizar',
            filterRow: {
              filterTooltip: 'Filtro'
            }
          }
        }}
      />
    </div>
  )
}

CloseContactSubGrid.propTypes = {
  parentId: PropTypes.number.isRequired,
  getData: PropTypes.func.isRequired
}

export default CloseContactSubGrid
