import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import {
  Tooltip,
  TextField,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Input,
  InputLabel
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import { GetApp } from '@material-ui/icons'
import { CSVLink } from 'react-csv'
import { DETAIL_COLUMNS } from './config'

const MIN_DATE = moment().subtract(60, 'days')
const MAX_DATE = moment()
const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    margin: '10px'
  },
  button: {
    margin: '10px'
  },
  csvLink: {
    color: 'white',
    textDecoration: 'none'
  }
}))

const CloseContactToolbar = ({ filters, loading, csvData, onSearch, onExportCSV }) => {
  const classes = useStyles()
  const csvLinkRef = useRef()
  const [exporting, setExporting] = useState(false)
  const csvFormattedDateFrom = moment(filters.dateFrom).format('DD-MM-YYYY')
  const csvFormattedDateTo = moment(filters.dateTo).format('DD-MM-YYYY')
  const csvFilename = `Contactos estrechos -- ${filters.user} -- ${csvFormattedDateFrom} - ${csvFormattedDateTo}.csv`

  const getCSVHeaders = () => {
    return DETAIL_COLUMNS.map((column) => ({
      label: column.title,
      key: column.field
    }))
  }

  const handleChangeExposurePeriod = (e) => {
    if (e.target.validity.valid) filters.setExposurePeriod(Number(e.target.value))
  }

  const handleSearch = () => {
    setExporting(false)
    onSearch()
  }

  const handleExportCSV = () => {
    setExporting(true)
    onExportCSV()
  }

  useEffect(() => {
    if (exporting && csvData !== undefined && csvLinkRef.current?.link) {
      setExporting(false)
      setTimeout(() => csvLinkRef.current.link.click())
    }
  }, [csvData])

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        className={classes.input}
        name="user"
        label="RUT/USUARIO"
        value={filters.user}
        onChange={(e) => filters.setUser(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <DatePicker
        className={classes.input}
        name="dateFrom"
        label="Desde"
        format="DD/MM/yyyy"
        value={filters.dateFrom}
        onChange={(v) => filters.setDateFrom(v)}
        minDate={MIN_DATE}
        maxDate={filters.dateTo}
        InputLabelProps={{ shrink: true }}
      />
      <DatePicker
        className={classes.input}
        name="dateTo"
        label="Hasta"
        format="DD/MM/yyyy"
        value={filters.dateTo}
        onChange={(v) => filters.setDateTo(v)}
        minDate={filters.dateFrom}
        maxDate={MAX_DATE}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        className={classes.input}
        name="exposurePeriod"
        label="Período de exposición"
        helperText="Minutos"
        type="number"
        value={filters.exposurePeriod}
        onChange={handleChangeExposurePeriod}
        InputLabelProps={{ shrink: true }}
        InputProps={{ inputMode: 'numeric', pattern: '[0-9]*', inputProps: { min: 0, max: 15 } }}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        disabled={loading}
        onClick={handleSearch}
      >
        Buscar
      </Button>
      <Tooltip title="Exportar datos">
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          disabled={loading || exporting}
          onClick={handleExportCSV}
          startIcon={exporting && <CircularProgress size={24} />}
        >
          {exporting && 'Generando archivo...'}
          {!exporting && <GetApp />}
        </Button>
      </Tooltip>
      <CSVLink
        className={classes.csvLink}
        filename={csvFilename}
        headers={getCSVHeaders()}
        data={csvData ?? []}
        ref={csvLinkRef}
      />
    </form>
  )
}

CloseContactToolbar.propTypes = {
  filters: PropTypes.shape({
    user: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    exposurePeriod: PropTypes.number,
    setUser: PropTypes.func,
    setDateFrom: PropTypes.func,
    setDateTo: PropTypes.func,
    setExposurePeriod: PropTypes.func
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  csvData: PropTypes.arrayOf(PropTypes.object),
  onSearch: PropTypes.func.isRequired,
  onExportCSV: PropTypes.func.isRequired
}

export default CloseContactToolbar
