import React from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from '@material-ui/core'

const DeleteUserModal = ({ open, handleClose, handleDelete }) => {
  const useStyles = makeStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  })

  const { modal } = useStyles()

  return (<Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Fade in={open}>
      <div
        style={{
          color: 'Black',
          backgroundColor: '#DEDEDE',
          border: 'solid 2px',
          borderColor: 'black',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <h2 id="transition-modal-title">¿Está seguro que quiere eliminar este empleado?</h2>
        <Button variant="contained" onClick={handleClose} align="left" style={{ backgroundColor: '#0989F3' }}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleDelete}
          align="right"
          style={{ backgroundColor: '#F53333', marginLeft: '60%' }}
        >
          Eliminar
        </Button>
      </div>
    </Fade>
  </Modal>)
}

export default DeleteUserModal
