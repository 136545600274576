import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter, useParams } from 'react-router-dom'

import useAxios from '../customHooks/useAxios'
import IndividualUserHandler from '../components/IndividualUserHandler'
import Loading from '../components/Loading'

const EditUser = ({ history }) => {
  const { id } = useParams()

  const [updateCount, setUpdateCount] = useState(0)
  const [{ data, loading, error }] = useAxios(
    {
      url: `/users/${id}`,
      method: 'GET',
    },
  )

  const [{ error: updateError, loading: loadingUpdate }, updateUser] = useAxios({ manual: true })

  const { loadingUser } = makeStyles({
    noUsersLabel: {
      marginLeft: 'auto',
      marginRight: 'auto',
      fontWeight: 'bold'
    }
  })
  
  console.info('le update error', updateError)

  const handleUserUpdate = async (data) => {
    await updateUser({ url: `/users/${id}`, method: 'PUT', data, body: data }, {})

    setUpdateCount(updateCount + 1)
  }

  if (loading) return <Loading containerClass={loadingUser} thickness={6} />

  if (error) return <label>Error obteniendo el usuario, por favor, pruebe nuevamente más tarde</label>

  if (updateCount && updateError) return <label>Error al editar el usuario, por favor, pruebe nuevamente más tarde</label>

  return <IndividualUserHandler user={data} onSubmit={handleUserUpdate} buttonText="EDITAR" />
}

export default withRouter(EditUser)
