import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import { Button, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { DEFAULT_ID, NO_STATE } from '../helpers/constants'

const UsersFilter = ({ sedes = [], roles = [], submitFilters }) => {
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState(NO_STATE)
  const [role, setRole] = useState(DEFAULT_ID)
  const [sede, setSede] = useState(DEFAULT_ID)

  const useStyles = makeStyles({
    usersForm: {
      display: 'flex',
      margin: '1em'
    },
    usersFormInput: {
      margin: '.4em'
    },
    usersFormLabel: {
      marginRight: '.4em',
      marginTop: '.8em'
    }
  })

  const { usersForm, usersFormInput, usersFormLabel } = useStyles()

  const campusOptions = [{ campus_name: 'Seleccione una sede', id: DEFAULT_ID }, ...sedes]

  const statusOptions = [{ label: 'Seleccione un estado', value: NO_STATE }, { label: 'Activo', value: true }, { label: 'Inactivo', value: false }]

  const roleOptions = [{ role_name: 'Seleccione un rol', id: DEFAULT_ID }, ...roles]

  const cleanFilters = () => {
    setEmail('')
    setStatus(NO_STATE)
    setRole(DEFAULT_ID)
    setSede(DEFAULT_ID)

    submitFilters({})
  }

  return (
    <form className={usersForm}>
      <div className={usersFormInput}>
        <label className={usersFormLabel}>Email:</label>
        <TextField
          id="email"
          placeholder="email@dominio.com"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </div>
      <div className={usersFormInput}>
        <label className={usersFormLabel}>Estado:</label>
        <Select id="estado" value={status} onChange={({ target: { value } }) => setStatus(value)}>
          {statusOptions.map(({ label, value }) => <MenuItem value={value}>{label}</MenuItem>)}
        </Select>
      </div>
      <div className={usersFormInput}>
        <label className={usersFormLabel}>Rol:</label>
        <Select id="rol" value={role} onChange={({ target: { value } }) => setRole(value)}>
          {roleOptions.map(({ role_name, id }) => <MenuItem value={id}>{role_name}</MenuItem>)}
        </Select>
      </div>
      <div className={usersFormInput}>
        <label className={usersFormLabel}>Sede:</label>
        <Select id="sede" value={sede} onChange={({ target: { value } }) => setSede(value)}>
          {campusOptions.map(({ id, campus_name }) => <MenuItem value={id}>{campus_name}</MenuItem>)}
        </Select>
      </div>
      <Button onClick={() => submitFilters({ email, status, role, sede })}>Filtrar</Button>
      <Button onClick={cleanFilters}>Limpiar filtros</Button>
    </form>
  )

}

export default UsersFilter
