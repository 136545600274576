import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Switch from '@material-ui/core/Switch'

import Loading from './Loading'
import DeleteUserModal from './DeleteUserModal'
import { fromIsActiveToBoolean } from '../helpers/formatters'
import Pagination from './Pagination'
import { PAGE_SIZE } from '../helpers/constants'

const UsersTable = ({
  users,
  loading,
  userCount,
  handleDelete,
  updateState,
  disableActions,
  page,
  setPage
}) => {
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    noUsersLabel: {
      marginLeft: 'auto',
      marginRight: 'auto',
      fontWeight: 'bold'
    }
  })

  const { table, noUsersLabel } = useStyles()
  const [open, setOpen] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [rowsInfo, setRowsInfo] = useState([])

  useEffect(() => {
    const start = page * PAGE_SIZE
    const end = start + PAGE_SIZE

    const currentPageContent = users.slice(start, end)

    setRowsInfo(currentPageContent)
  }, [page, users])

  if (loading) return <Loading containerClass={noUsersLabel} thickness={6} />

  if (!userCount) return <label className={noUsersLabel}>No existen usuarios creados</label>

  if (!users.length) return <label className={noUsersLabel}>No existen usuarios que coincidan con el filtro</label>

  const handleOpen = (id) => {
    setOpen(true)
    setCurrentId(id)
  }

  const handleClose = () => {
    setOpen(false)
    setCurrentId()
  }

  const deleteAndClose = () => {
    handleDelete(currentId)
    handleClose()
  }

  const columnNames = ['Nombre', 'Email', 'Rol', 'Última Edición', 'Activo', 'Editar', 'Eliminar']

  return (
    <TableContainer component={Paper}>
      <Table className={table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columnNames.map(columnName => <TableCell align="center" key={columnName}>{columnName}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsInfo.map(({ id, full_name, email, is_active, role = {}, updated_at }) => (
            <TableRow key={id}>
              <TableCell align="center">{full_name}</TableCell>
              <TableCell align="center">{email}</TableCell>
              <TableCell align="center">{role.role_name}</TableCell>
              <TableCell align="center">{updated_at.split('.')[0].split('T').join(' ')}</TableCell>
              <TableCell align="center">
                No<Switch checked={fromIsActiveToBoolean(is_active)} onChange={() => updateState(id)} />Si
              </TableCell>
              <TableCell align="center">
                <Button href={'/editUser/' + id} disabled={disableActions}>
                  <EditIcon style={{ color: disableActions ? 'grey' : '#0989F3' }} />
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button onClick={() => handleOpen(id)} disabled={disableActions}>
                  <DeleteIcon style={{ color: disableActions ? 'grey' : 'red' }} />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination count={users.length} page={page} rowsPerPage={PAGE_SIZE} onPageChange={setPage} />
      <DeleteUserModal open={open} handleClose={handleClose} handleDelete={deleteAndClose} />
    </TableContainer>
  )
}

export default UsersTable
