import React from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import { Person } from '@material-ui/icons'
import { Auth } from 'aws-amplify'
import { useAuth } from 'customHooks/useAuth'

const { REACT_APP_ISOLOGO } = process.env

const useStyles = makeStyles((theme) => ({
  AppbarRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[0]
  },
  ToolbarRoot: {
    borderTop: `6px solid ${theme.palette.secondary.light}`,
    boxShadow: theme.shadows[1],
    minHeight: 66,
    zIndex: 2,
    padding: '0px 15px'
  },
  flexGrow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  logoAppbar: {
    width: 140,
    height: 40,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginRight: 10,
    marginLeft: -1
  },
  name: {
    color: theme.text.primary,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16
  },
  iconPerson: {
    fontSize: 20
  },
  headerTitle: {
    paddingLeft: 12,
    borderLeft: '2px solid #d8d8d8',
    color: theme.text.primary,
    margin: 0
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))

const PrimarySearchAppBar = (props) => {
  const { user } = useAuth()
  const classes = useStyles()

  function handleProfileMenuOpen(event) {}

  const menuId = 'primary-search-account-menu'

  return (
    <>
      <AppBar position="static" className={classes.AppbarRoot}>
        <Toolbar className={classes.ToolbarRoot}>
          <div
            style={{
              display: 'block',
              width: '30px',
              height: '6px',
              backgroundColor: '#fff',
              position: 'absolute',
              top: '-6px',
              left: '15px'
            }}
          />
          <div
            className={classes.logoAppbar}
            style={{ backgroundImage: `url(${REACT_APP_ISOLOGO})`, backgroundSize: 'contain' }}
          />

          <Box fontFamily="Fjalla One" className={classes.headerTitle} fontSize="h5.fontSize" m={1}>
            WiFi HeatMaps
          </Box>

          <div className={classes.flexGrow} />
          <div className={classes.sectionDesktop}>
            <Box
              edge="end"
              aria-label="Account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              className={classes.name}
              fontFamily={'Roboto Condensed'}
            >
              <Person className={classes.iconPerson} />
              <Box ml={0.5} width={180}>
                {user && user.full_name}
              </Box>
              <Box ml={0.5} width={100}>
                <button onClick={() => Auth.signOut()}>Salir</button>
              </Box>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default withRouter(PrimarySearchAppBar)
