import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { IntlProvider } from 'react-intl'
import AppLocale from './lang'
import { flattenMessages } from './lang/utils'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/es'

import NotFound from 'pages/NotFound'
import Layout from 'components/Layout'
import Users from 'pages/Users'
import EditUser from 'pages/EditUser'
import CreateUser from 'pages/CreateUser'
import CloseContact from 'pages/CloseContact'

function App() {
  const currentAppLocale = AppLocale['es']

  return (
    <Router>
      <IntlProvider locale={currentAppLocale.locale} messages={flattenMessages(currentAppLocale.messages)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Layout>
            <Switch>
              <Route exact path="/">
                <Redirect to="/closeContact" />
              </Route>
              <Route exact path="/users" component={Users} />
              <Route exact path="/editUser/:id" component={EditUser} />
              <Route exact path="/createUser" component={CreateUser} />
              <Route exact path="/closeContact" component={CloseContact} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </MuiPickersUtilsProvider>
      </IntlProvider>
    </Router>
  )
}

export default App
