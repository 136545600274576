import Amplify, { Auth, Hub } from 'aws-amplify'
import { useState, useEffect, useCallback } from 'react'
import useAxios from './useAxios'

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    region: process.env.REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_CDN_URL,
      redirectSignOut: process.env.REACT_APP_CDN_URL,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
})

const listener = (data) => {
  switch (data.payload.event) {
    case 'signIn':
      console.log('user signed in')
      break
    case 'signUp':
      console.log('user signed up')
      break
    case 'signOut':
      console.log('user signed out')
      break
    case 'signIn_failure':
      console.error('user sign in failed')
      break
    case 'tokenRefresh':
      console.log('token refresh succeeded')
      break
    case 'tokenRefresh_failure':
      console.error('token refresh failed')
      break
    case 'configured':
      console.log('the Auth module is configured')
    default:
      console.log(data)
  }
}

Hub.listen('auth', listener)

export const useAuth = (immediate = true) => {
  const [status, setStatus] = useState('idle')
  const [value, setValue] = useState(null)
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [_, executeAxios] = useAxios({ manual: true })

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus('pending')
    setValue(null)
    setUser(null)
    setError(null)

    return Auth.currentAuthenticatedUser()
      .then((response) => {
        setValue(response)
        return executeAxios({ url: `/users/email/${response.attributes.email}` })
      })
      .then((response) => {
        setUser(response.data)
        setStatus('success')
      })
      .catch((ex) => {
        console.error(ex)
        localStorage.clear()
        setError(ex)
        return Auth.federatedSignIn()
      })
  }, [Auth])

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])
  return { execute, status, value, user, error }
}
