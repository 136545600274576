import React from 'react'
import App from './App'
import { useAuth } from './customHooks/useAuth'
import InitLoading from './components/InitLoading'

const AppSecure = () => {
  const { user } = useAuth()

  return !!user ? <App /> : <InitLoading />
}

export default AppSecure
