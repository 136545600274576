import axios from 'axios'
import { makeUseAxios } from 'axios-hooks'
import { Auth } from 'aws-amplify'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_END
})

instance.interceptors.request.use(
  async function (config) {
    const currenySession = await Auth.currentSession()
    const jwtToken = currenySession.getIdToken().getJwtToken()
    console.log('token', jwtToken)

    config.headers = {
      ...config.headers,
      Authorization: jwtToken
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default makeUseAxios({
  axios: instance
})
