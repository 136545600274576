import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { withRouter } from 'react-router-dom'

import useAxios from '../customHooks/useAxios'

const IndividualUserHandler = ({ user, onSubmit, buttonText, history }) => {
  const [role, setRole] = useState('')
  const [campus, setCampus] = useState('')
  const [status, setStatus] = useState(true)
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [charge, setCharge] = useState('')
  const [errors, setErrors] = useState([])

  const [{ data: rolesList = [] }] = useAxios(
    {
      url: '/roles',
      method: 'GET',
    },
  )

  const [{ data: campusList = [] }] = useAxios(
    {
      url: '/campus',
      method: 'GET',
    },
  )

  useEffect(() => {
    if (user) {
      const {
        role: { id: roleId },
        campus: [{ id: campusId }],
        is_active,
        full_name,
        email: userEmail,
        charge: userCharge
      } = user

      console.info('le meme', user, {roleId,
        campusId,
        is_active,
        full_name,
        userEmail,
        userCharge})

      setRole(roleId)
      setCampus(campusId)
      setStatus(is_active)
      setFullName(full_name)
      setEmail(userEmail)
      setCharge(userCharge)
    }

  }, [user])

  const handleChangeRole = ({ target: { value } }) => {
    setRole(value)
  }
  const handleChangeCampus = ({ target: { value } }) => {
    setCampus(value)
  }
  const handleChangeStatus = ({ target: { checked } }) => {
    setStatus(checked)
  }

  const goBack = () => {
    history.push('/users')
  }

  const useStyles = makeStyles({
    formGroupContainer: {
      display: 'flex',
      width: '100%',
      'justify-content': 'space-between',
      marginTop: '1em',
      marginBottom: '1em'
    },
    formSelectGroupContainer: {
      display: 'flex',
      width: '95%',
      'justify-content': 'space-between',
      marginTop: '1em',
      marginBottom: '1em'
    },
    labelInputGroup: {
      display: 'flex',
      width: '50%',
      flexDirection: 'column',
      marginRight: '5%'
    },
    textField: {
      width: '100%'
    },
    selectInputGroup: {
      display: 'flex',
      width: '30%',
      flexDirection: 'column',
    },
    singleInputGroup: {
      display: 'flex',
      width: '95%',
      flexDirection: 'column'
    },
    submitFormButton: {
      backgroundColor: '#24D924',
      width: 'max-content',
      margin: '1em'
    },
    formControlButtons: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    cancelFormButton: {
      backgroundColor: 'grey',
      width: 'max-content',
      margin: '1em'
    },
    checkboxInputGroup: {
      display: 'flex',
      width: '30%',
      flexDirection: 'column',
    },
    errorList: {
      backgroundColor: '#f00c',
      padding: '2em',
      borderRadius: '5px',
      width: '30%'
    }
  })

  const isValidEmail = () => {
    // Al dirs
    // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Only inacap emails
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@inacap.cl$/;

    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = async () => {
    const newErrors = []

    if (!fullName) newErrors.push('El nombre completo del usuario es obligatorio.')
    if (!email) newErrors.push('El email del usuario es obligatorio.')
    else if (!isValidEmail()) newErrors.push('El email debe tener el formato direccion@inacap.cl.')
    if (!role) newErrors.push('Debe seleccionar uno de los roles.')

    setErrors(newErrors)

    if (!newErrors.length) {
      await onSubmit({
        email,
        full_name: fullName,
        is_active: status,
        role: { id: role },
        campus: [{ id: campus }]
      })

      history.push('/users')
    }
  }

  const {
    formGroupContainer,
    labelInputGroup,
    textField,
    selectInputGroup,
    formSelectGroupContainer,
    submitFormButton,
    singleInputGroup,
    checkboxInputGroup,
    errorList,
    formControlButtons,
    cancelFormButton
  } = useStyles()

  return (
    <FormControl style={{ marginLeft: '10%', width: '90%' }}>
      <div className={formGroupContainer}>
        <div className={labelInputGroup}>
          <label>Nombre y Apellidos</label>
          <TextField value={fullName} className={textField} id="name" placeholder="Nombre Y Apellidos" onChange={({ target: { value } }) => setFullName(value)} />
        </div>
        <div className={labelInputGroup}>
          <label>Email</label>
          <TextField value={email} className={textField} id="email" placeholder="ejemplo@mail.com" onChange={({ target: { value } }) => setEmail(value)} />
        </div>
      </div>
      <div className={formGroupContainer}>
        <div className={singleInputGroup}>
          <label>Cargo</label>
          <TextField value={charge} className={textField} id="charge" placeholder="Cargo" onChange={({ target: { value } }) => setCharge(value)} />
        </div>
      </div>
      <div className={formSelectGroupContainer}>
        <div className={selectInputGroup}>
          <label>Role</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            onChange={handleChangeRole}
          >
            {rolesList.map(({ role_name, id }) => <MenuItem value={id}>{role_name}</MenuItem>)}
          </Select>
        </div>
        <div className={selectInputGroup}>
          <label>Campus</label>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={campus}
            onChange={handleChangeCampus}
          >
            {campusList.map(({ campus_name, id }) => <MenuItem value={id}>{campus_name}</MenuItem>)}
          </Select>
        </div>
        <div className={checkboxInputGroup}>
        <label>Activo</label>
          <Checkbox
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            checked={status}
            onChange={handleChangeStatus}
          />
        </div>
      </div>
      {!!errors.length && <ul className={errorList}>
        {errors.map(errorText => <li>{errorText}</li>)}
      </ul>}
      <div className={formControlButtons}>
        <Button className={cancelFormButton} onClick={goBack}>Cancelar</Button>
        <Button className={submitFormButton} onClick={handleSubmit}>{buttonText}</Button>
      </div>
    </FormControl>
  )
}

export default withRouter(IndividualUserHandler)
