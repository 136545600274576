import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: 0,
  },
}))

export default function Loading(props) {
  const classes = useStyles()

  return (
    <div className={props.containerClass}>
      <CircularProgress thickness={props.thickness} {...props} className={classes.progress} color={props.color || 'secondary'} />
    </div>
  )
}
