export const paginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

export const isArray = (array) => {
  return Array.isArray(array)
}

export const isObject = (obj) => {
  return obj === Object(obj) && !isArray(obj) && typeof obj !== 'function'
}

export const isEmpty = (x) => {
  if (isArray(x)) return x.length === 0
  if (isObject(x)) return Object.keys(x).length === 0
  return !x
}
