import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import CloseContactSubGrid from './CloseContactSubGrid'
import { HEADER_COLUMNS } from './config'

const COLUMNS_DEFAULT_SORT_KEY = 'defaultSort'

const CloseContactGrid = ({ loading, dataHeaders, getIntervalDetail, isHeaderExpanded, onToggleHeader }) => {
  const [sorting, setSorting] = useState({ columnId: 4, direction: 'asc' })
  const [columns, setColumns] = useState(HEADER_COLUMNS)

  const getDetailPanel = (d) => <CloseContactSubGrid parentId={d.id} getData={getIntervalDetail} />
  const toggleDirection = (d) => (d === 'asc' ? 'desc' : 'asc')

  useEffect(() => {
    const _columns = columns.map((c, i) => {
      if (i !== sorting.columnId) {
        delete c[COLUMNS_DEFAULT_SORT_KEY]
      } else {
        c[COLUMNS_DEFAULT_SORT_KEY] = sorting.direction
      }
      return c
    })
    setColumns(_columns)
  }, [sorting])

  const handleOrderChange = (columnId, direction) => {
    const _columnId = columnId >= 0 ? columnId : sorting.columnId
    const _direction = _columnId !== sorting.columnId ? 'desc' : toggleDirection(sorting.direction)
    setSorting({ columnId: _columnId, direction: _direction })
  }

  return (
    <div>
      <MaterialTable
        isLoading={loading}
        columns={columns}
        data={dataHeaders
          .sort((a, b) => columns[sorting.columnId].sortingFunc(a, b, sorting.direction))
          .map((d) => ({
            ...d,
            tableData: {
              showDetailPanel: isHeaderExpanded(d.id) ? getDetailPanel : ''
            }
          }))}
        detailPanel={(d) => onToggleHeader(d)}
        onRowClick={(_, d) => onToggleHeader(d)}
        onOrderChange={handleOrderChange}
        options={{
          filtering: true,
          sorting: true,
          search: false,
          grouping: false,
          toolbar: false,
          draggable: false,
          showFirstLastPageButtons: false,
          exportButton: false,
          showTitle: false,
          padding: 'default',
          actionsColumnIndex: -1,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20, 25],
          emptyRowsWhenPaging: false,
          detailPanelType: 'single'
        }}
        localization={{
          toolbar: {
            searchPlaceholder: 'Buscar',
            nRowsSelected: '{0} filas seleccionadas'
          },
          pagination: {
            labelRowsSelect: 'Filas',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsPerPage: 'Filas por página',
            firstTooltip: 'Primera página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Siguiente página',
            lastTooltip: 'Última página'
          },
          body: {
            emptyDataSourceMessage: 'No hay datos para visualizar',
            filterRow: {
              filterTooltip: 'Filtro'
            }
          }
        }}
      />
    </div>
  )
}

CloseContactGrid.propTypes = {
  loading: PropTypes.bool,
  dataHeaders: PropTypes.arrayOf(PropTypes.object),
  getIntervalDetail: PropTypes.func,
  isHeaderExpanded: PropTypes.func,
  onToggleHeader: PropTypes.func
}

CloseContactGrid.defaultProps = {
  loading: false,
  dataHeaders: [],
  getIntervalDetail: () => {},
  isHeaderExpanded: () => {},
  onToggleHeader: () => {}
}

export default CloseContactGrid
