import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { instance as axios } from 'customHooks/useAxios'
import { paginate } from 'helpers/utils'
import { API_ENDPOINTS } from '../config/apiBackend'

const SIMULTANEOUS_REQUESTS = process.env.REACT_APP_CLOSE_CONTACT_SIMULTANEOUS_REQUESTS
const PRELOAD_PAGES = process.env.REACT_APP_CLOSE_CONTACT_PRELOAD_PAGES
const ESTIMATED_TIME_PER_INTERVAL = process.env.REACT_APP_CLOSE_CONTACT_ESTIMATED_TIME_PER_INTERVAL
const MAXIMUM_EXPORT_ESTIMATED_TIME = process.env.REACT_APP_CLOSE_CONTACT_MAXIMUM_EXPORT_ESTIMATED_TIME

const useCloseContact = (pageSize = 5, pageNumber = 1) => {
  const [backgroundBatchCount, setBackgroundBatchCount] = useState(0)
  const [preLoadingHeaders, setPreLoadingHeaders] = useState(false)
  const [preLoadingDetails, setPreLoadingDetails] = useState(false)
  const [backgroundLoading, setBackgroundLoading] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [intervalHeaders, setIntervalHeaders] = useState()
  const [intervalDetails, setIntervalDetails] = useState()
  const [intervalHeadersExpanded, setIntervalHeadersExpanded] = useState([])
  const [intervalHeadersLoaded, setIntervalHeadersLoaded] = useState([])
  const [csvData, setCSVData] = useState()
  const [filters, setFilters] = useState({
    user: '',
    dateFrom: moment().format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    exposurePeriod: 15
  })

  const setUser = (value) => setFilters({ ...filters, user: value })
  const setDateFrom = (value) => setFilters({ ...filters, dateFrom: moment(value).format('YYYY-MM-DD') })
  const setDateTo = (value) => setFilters({ ...filters, dateTo: moment(value).format('YYYY-MM-DD') })
  const setExposurePeriod = (value) => setFilters({ ...filters, exposurePeriod: value })
  const isIntervalHeaderExpanded = (id) => intervalHeadersExpanded.includes(id)
  const isIntervalHeaderLoaded = (id) => intervalHeadersLoaded.includes(id)

  const toggleIntervalHeader = (id) => {
    if (isIntervalHeaderExpanded(id)) {
      setIntervalHeadersExpanded(intervalHeadersExpanded.filter((intId) => intId !== id))
    } else {
      setIntervalHeadersExpanded([...intervalHeadersExpanded, id])
    }
  }

  const getIntervalDetail = async (intervalId) => {
    let result
    if (isIntervalHeaderLoaded(intervalId)) {
      result = intervalDetails.find((d) => d.intervalId === intervalId)
    } else {
      result = await _fetchIntervalDetail(intervalId)
    }
    return result?.data
  }

  const _isSameInterval = (a, b) => {
    return (
      a.user === b.user &&
      a.ap_name === b.ap_name &&
      a.connection === b.connection &&
      a.disconnection === b.disconnection
    )
  }

  const _getCSVData = () => {
    let details = intervalDetails ? [...intervalDetails] : []
    const data = details
      .flatMap((d) => d.data)
      .map((d, i) => ({ ...d, id: i }))
      .filter((val, _, self) => !self.some((s) => val.id < s.id && _isSameInterval(val, s)))
      .map((d) => ({
        ...d,
        connection: moment(d.connection).format('DD/MM/YYYY HH:mm:ss'),
        disconnection: moment(d.disconnection).format('DD/MM/YYYY HH:mm:ss')
      }))
      .sort((c, n) => new Date(c.connection) - new Date(n.connection))
    return data
  }

  const _fetchIntervalHeaders = () => {
    const data = {
      user: filters.user,
      dateFrom: moment(filters.dateFrom).startOf('day').utc().format(),
      dateTo: moment(filters.dateTo).endOf('day').utc().format(),
      exposurePeriod: filters.exposurePeriod,
      datetime_search: moment.utc().format()
    }
    return axios
      .post(API_ENDPOINTS.CLOSE_CONTACT_BY_USER, data)
      .then((response) => response.data.map((h, i) => ({ ...h, id: i })))
      .catch((error) => {
        console.error(error)
        return Promise.reject(error)
      })
  }

  const _isAllHeadersLoaded = () => {
    return intervalHeadersLoaded?.length === intervalHeaders?.length
  }

  const _fetchIntervalDetail = (intervalId) => {
    const interval = intervalHeaders.find((int) => int.id === intervalId)
    const data = {
      interval: interval,
      exposurePeriod: filters.exposurePeriod,
      datetime_search: moment.utc().format()
    }
    return axios
      .post(API_ENDPOINTS.CLOSE_CONTACT_BY_INTERVAL, data)
      .then((response) => {
        return { intervalId: interval.id, data: response.data }
      })
      .catch((error) => {
        console.error(error)
        return { intervalId: interval.id, data: [] }
      })
  }

  const _paginateUnloadedIntervalHeaders = (quantity, offset) => {
    return intervalHeaders
      .slice(offset)
      .filter((header) => !intervalHeadersLoaded.includes(header.id))
      .slice(0, quantity)
  }

  const _getLoadedHeadersString = (cantBatchs) => {
    const _headersTotal = intervalHeaders.length
    const _headersLoaded = Math.min(_headersTotal, intervalHeadersLoaded.length + cantBatchs * SIMULTANEOUS_REQUESTS)
    const _batchsTotal = Math.ceil(_headersTotal / SIMULTANEOUS_REQUESTS)
    const _batchsLoaded = Math.ceil(_headersLoaded / SIMULTANEOUS_REQUESTS)

    return `${_batchsLoaded}/${_batchsTotal} [${_headersLoaded}/${_headersTotal}]`
  }

  const _loadIntervalHeaders = () => {
    return _fetchIntervalHeaders().then((response) => {
      setIntervalHeaders(response)
    })
  }

  const _loadIntervalDetails = async (quantity, offset = 0) => {
    if (quantity === undefined) quantity = intervalHeaders.length

    const headers = _paginateUnloadedIntervalHeaders(quantity, offset)
    const quanFetchs = Math.ceil(headers.length / SIMULTANEOUS_REQUESTS)
    if (quanFetchs === 0) return

    let updatedIntervalHeadersLoaded = intervalHeadersLoaded ? [...intervalHeadersLoaded] : []
    let updatedIntervalDetails = intervalDetails ? [...intervalDetails] : []
    for (let fetchNum = 1; fetchNum <= quanFetchs; fetchNum++) {
      console.log('DEBUG', '--', '-/', 'Begin load batch Details', _getLoadedHeadersString(fetchNum))

      const headersToLoad = paginate(headers, SIMULTANEOUS_REQUESTS, fetchNum)
      const headersToLoadIds = headersToLoad.map((d) => d.id)
      const detailsPromises = headersToLoad.map((h) => _fetchIntervalDetail(h.id))
      const detailsResults = await Promise.all(detailsPromises)

      updatedIntervalHeadersLoaded = updatedIntervalHeadersLoaded.concat(headersToLoadIds)
      updatedIntervalDetails = updatedIntervalDetails.concat(detailsResults)

      setIntervalHeadersLoaded(headersToLoadIds.filter((v, i, a) => a.indexOf(v) === i))
      setIntervalDetails(updatedIntervalDetails)
      console.log('DEBUG', '--', '-\\', 'End load batch Details')
    }

    setIntervalHeadersLoaded(updatedIntervalHeadersLoaded)
    setIntervalDetails(updatedIntervalDetails)
  }

  const _preloadHeaders = () => {
    console.time('DEBUG - PreloadHeaders')
    console.log('DEBUG', '-/', 'Begin preload Headers')
    _loadIntervalHeaders()
      .then(() => {
        setPreLoadingHeaders(false)
        console.log('DEBUG', '-\\', 'End preload Headers')
        console.timeEnd('DEBUG - PreloadHeaders')
        setPreLoadingDetails(true)
      })
      .catch((error) => {
        console.error('DEBUG', error)
        setPreLoadingHeaders(false)
      })
  }

  const _preloadDetails = () => {
    console.time('DEBUG - PreloadDetails')
    console.log('DEBUG', '-/', 'Begin preload Details', pageSize * PRELOAD_PAGES)
    _loadIntervalDetails(pageSize * PRELOAD_PAGES)
      .then(() => {
        setPreLoadingDetails(false)
        console.log('DEBUG', '-\\', 'End preload Details')
        console.timeEnd('DEBUG - PreloadDetails')
        setBackgroundLoading(true)
      })
      .catch((error) => {
        console.error('DEBUG', error)
        setPreLoadingDetails(false)
      })
  }

  const _clear = () => {
    setExporting(false)
    setCSVData()
    setIntervalHeadersExpanded([])
    setIntervalHeadersLoaded([])
    setIntervalHeaders()
    setIntervalDetails()
  }

  const cancel = () => {
    if (preLoadingHeaders || preLoadingDetails || backgroundLoading) {
      console.log('DEBUG', 'Canceling....................')
      setCancelling(true)
    }
  }

  const search = () => {
    cancel()
    console.log('DEBUG', 'Searching....................')
    setPreLoadingHeaders(true)
  }

  const exportCSV = () => {
    console.log('DEBUG', 'Exporting....................')
    setCSVData()
    if (!backgroundLoading && intervalDetails !== undefined) {
      const intervalsLoadedInBackground = Math.max(0, intervalDetails.length - pageSize * PRELOAD_PAGES)
      const batchsLoadedInBackground = Math.ceil(intervalsLoadedInBackground / SIMULTANEOUS_REQUESTS)
      const estimatedTime = (batchsLoadedInBackground * ESTIMATED_TIME_PER_INTERVAL) / 2
      const timeout = Math.min(MAXIMUM_EXPORT_ESTIMATED_TIME, estimatedTime)

      console.log('DEBUG', `Exporting timeout ${timeout} [${estimatedTime}]`)
      setTimeout(() => setExporting(true), timeout)
    } else {
      setExporting(true)
    }
  }

  useEffect(() => {
    if (cancelling) return

    if (preLoadingHeaders) {
      _clear()
      _preloadHeaders()
    } else if (preLoadingDetails) {
      _preloadDetails()
    } else if (backgroundLoading) {
      console.time('DEBUG -\\ Backload Details')
      console.log('DEBUG', '-/', 'Begin backload Details')
    }
  }, [preLoadingHeaders, preLoadingDetails, backgroundLoading, cancelling])

  useEffect(() => {
    if (!backgroundLoading) return
    if (cancelling) {
      setBackgroundLoading(false)
      setCancelling(false)
      return
    }
    if (_isAllHeadersLoaded()) {
      console.timeEnd('DEBUG -\\ Backload Details')
      setBackgroundLoading(false)
      return
    }

    _loadIntervalDetails(SIMULTANEOUS_REQUESTS).then(() => {
      setBackgroundBatchCount(backgroundBatchCount + 1)
    })
  }, [backgroundLoading, backgroundBatchCount])

  useEffect(() => {
    if (exporting && !backgroundLoading) {
      setCSVData(_getCSVData())
      setExporting(false)
    }
  }, [exporting, backgroundLoading])

  return [
    {
      preLoading: preLoadingHeaders || preLoadingDetails,
      backgroundLoading,
      filters: { ...filters, setUser, setDateFrom, setDateTo, setExposurePeriod },
      intervalHeaders: intervalHeaders ?? [],
      intervalDetails: intervalDetails ?? [],
      csvData
    },
    search,
    cancel,
    exportCSV,
    toggleIntervalHeader,
    getIntervalDetail,
    isIntervalHeaderExpanded
  ]
}

export default useCloseContact
