import React from 'react'
import { withRouter } from 'react-router-dom'

import useAxios from '../customHooks/useAxios'
import IndividualUserHandler from 'components/IndividualUserHandler'

const CreateUser = () => {
  const [{ error }, createUser] = useAxios({
    url: '/users',
    method: 'POST'
  },
    { manual: true })

  const handleSubmit = async (data) => {
    await createUser({ data })
  }

  return <IndividualUserHandler onSubmit={handleSubmit} buttonText="CREAR" />
}

export default withRouter(CreateUser)
