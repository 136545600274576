import moment from 'moment-timezone'

const sortingString = (a, b, d) => a.toString().localeCompare(b) * (d === 'asc' ? 1 : -1)
const sortingNumber = (a, b, d) => (a - b) * (d === 'asc' ? 1 : -1)
const sortingDate = (a, b, d) => (new Date(a) - new Date(b)) * (d === 'asc' ? 1 : -1)

export const HEADER_COLUMNS = [
  {
    title: 'Access Point',
    field: 'ap_name',
    sortingFunc: sortingString
  },
  {
    title: 'Sede',
    field: 'location',
    sortingFunc: sortingString
  },
  {
    title: 'SSID',
    field: 'wlan',
    sortingFunc: sortingString
  },
  {
    title: 'AP MAC',
    field: 'ap_mac',
    sortingFunc: sortingString
  },
  {
    title: 'Conexión',
    field: 'connection',
    render: (row) => <span>{moment(row['connection']).format('DD/MM/YYYY HH:mm:ss')}</span>,
    filtering: false,
    sortingFunc: sortingDate
  },
  {
    title: 'Desconexión',
    field: 'disconnection',
    render: (row) => <span>{moment(row['disconnection']).format('DD/MM/YYYY HH:mm:ss')}</span>,
    filtering: false,
    sortingFunc: sortingDate
  }
]

export const DETAIL_COLUMNS = [
  {
    title: 'RUT/USUARIO',
    field: 'user',
    emptyValue: '--------'
  },
  {
    title: 'Access Point',
    field: 'ap_name'
  },
  {
    title: 'Sede',
    field: 'location'
  },
  {
    title: 'SSID',
    field: 'wlan'
  },
  {
    title: 'User MAC',
    field: 'user_mac'
  },
  {
    title: 'AP MAC',
    field: 'ap_mac'
  },
  {
    title: 'Conexión',
    field: 'connection',
    render: (row) => <span>{moment(row['connection']).format('DD/MM/YYYY HH:mm:ss')}</span>
  },
  {
    title: 'Desconexión',
    field: 'disconnection',
    render: (row) => <span>{moment(row['disconnection']).format('DD/MM/YYYY HH:mm:ss')}</span>
  }
]
