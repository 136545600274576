import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '2rem',
    padding: 15,
  },
  cardTitle: {
    margin: '0',
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  cardContent: {
    paddingTop: props => (props.noContentPadding ? 0 : 20),
  },
}))

export default function SectionCard(props) {
  const { cardTitle, children } = props
  const classes = useStyles(props)

  return (
    <Paper className={classes.root}>
      {cardTitle && (
        <Box className={classes.cardTitle} fontFamily="Fjalla One" fontSize="h5.fontSize" m={1}>
          {cardTitle}
        </Box>
      )}
      <div className={classes.cardContent}>{children}</div>
    </Paper>
  )
}
