import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const Pagination = ({ count, page, rowsPerPage, onPageChange }) => {
  const maxPage = Math.ceil(count / rowsPerPage) - 1

  const handleFirstPageButtonClick = () => {
    onPageChange(0)
  }

  const handleBackButtonClick = () => {
    onPageChange(page - 1)
  }

  const handleNextButtonClick = () => {
    onPageChange(page + 1)
  }

  const handleLastPageButtonClick = () => {
    onPageChange(Math.max(0, maxPage))
  }

  const { paginationContainer, pageInfoLabel } = makeStyles({
    paginationContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    pageInfoLabel: {
      paddingTop: '1em'
    }
  })()

  return (
    <div className={paginationContainer}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={!page}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={!page} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <label className={pageInfoLabel}>{page + 1} de {maxPage + 1}</label>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= maxPage}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= maxPage}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

export default Pagination
