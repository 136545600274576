import React from 'react'
import ReactDOM from 'react-dom'
import App from './main'

if (process.env.REACT_APP_STAGE !== 'local' && process.env.REACT_APP_STAGE !== 'dev') {
  console.log = () => {}
  console.time = () => {}
}

ReactDOM.render(<App />, document.getElementById('root'))
