import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import UsersFilter from '../components/UsersFilter'
import useAxios from '../customHooks/useAxios'
import UsersTable from '../components/UsersTable'
import { fromIsActiveToBoolean } from '../helpers/formatters'
import Snackbar from '../components/CustomSnackbar'
import { DEFAULT_ID, NO_STATE } from '../helpers/constants'

/* Errors */
const FETCH_ERROR_TEXT = 'Ocurrió un error al obtejer los usuarios'
const UPDATE_ERROR_TEXT = 'Ocurrió un error al editar al usuario '
const DELETE_ERROR_TEXT = 'Ocurrió un error al eliminar al usuario '

const Users = () => {
  const [{ data: userList, loading: loadingUsers, error: usersError }, getUsers] = useAxios(
    {
      url: '/users',
      method: 'GET',
    },
  )

  const [{ data: roles }] = useAxios(
    {
      url: '/roles',
      method: 'GET',
    },
  )

  const [{ data: campus }] = useAxios(
    {
      url: '/campus',
      method: 'GET',
    },
  )

  const [{ error: updateError }, updateUser] = useAxios({ manual: true })

  const [{ error: deleteError }, deleteUser] = useAxios({ manual: true })

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [currentUserName, setCurrentUserName] = useState('')

  useEffect(() => {
    if (!loadingUsers) {
      setLoading(false)
    }
  }, [loadingUsers])

  useEffect(() => {
    if (usersError && error !== FETCH_ERROR_TEXT) {
      setError(FETCH_ERROR_TEXT)
      return
    }

    // TODO why do they start failing?
    if (updateError && updateError.message !== 'Network Error' && !error.includes(UPDATE_ERROR_TEXT)) {
      setError(`${UPDATE_ERROR_TEXT}${currentUserName}`)
      return
    }

    if (deleteError && deleteError.message !== 'Network Error' && !error.includes(DELETE_ERROR_TEXT)) {
      setError(`${DELETE_ERROR_TEXT}${currentUserName}`)
      return
    }
  }, [usersError, updateError, deleteError])

  const changeUserStatus = async (userId) => {
    const userIndex = userList.findIndex(({ id }) => userId === id)
    const { is_active, full_name, ...rest } = userList[userIndex]

    const newIsActive = !fromIsActiveToBoolean(is_active)

    const data = { ...rest, full_name, is_active: newIsActive }

    await updateUser({ url: `/users/${userId}`, method: 'PUT', data, body: data }, {})
    userList[userIndex] = data

    const filteredUserIndex = filteredUsers.findIndex(({ id }) => userId === id)
    filteredUsers[filteredUserIndex] = data

    setCurrentUserName(full_name)
    getUsers()
    setFilteredUsers(filteredUsers)
  }

  const handleCloseError = () => {
    setError('')
  }

  const handleUserDeletion = async (userId) => {
    const [{ full_name}] = userList.find(({ id }) => userId === id)

    setCurrentUserName(full_name)
    await deleteUser({ url: `/users/${userId}`, method: 'DELETE' })

    const newFilteredUsers = filteredUsers.filter(({ id }) => userId !== id)

    getUsers()
    setFilteredUsers(newFilteredUsers)
  }

  const [filteredUsers, setFilteredUsers] = useState([])
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (userList && userList.length && !loadingUsers) setFilteredUsers(userList)
  }, [userList])

  const useStyles = makeStyles({
    userPage: {
      display: 'flex',
      flexDirection: 'column'
    },
    newUserButton: {
      backgroundColor: '#0989F3',
      margin: '3% 5%',
      width: '15%'
    }
  })

  const { newUserButton, userPage } = useStyles()

  const handleFilter = ({ email: filterEmail, status: filterStatus, role: filterRole, sede: filterSede }) => {
    if (!(filterEmail || filterStatus !== undefined || filterRole || filterSede)) {
      setFilteredUsers(userList)
      setPage(0)
      return
    }

    const afterFiltering = userList.filter(({ email, role = {}, is_active, campus }) => {
      const { id } = role
      const matchingFilters = []

      if (filterEmail) {
        const matchesEmail = email.toLowerCase().includes(filterEmail.toLowerCase())
        matchingFilters.push(matchesEmail)
      }
      if (filterStatus !== NO_STATE) {
        const matchesStatus = is_active === filterStatus
        matchingFilters.push(matchesStatus)
      }
      if (filterRole !== DEFAULT_ID) {
        const matchesRole = id === filterRole
        matchingFilters.push(matchesRole)
      }
      if (filterSede !== DEFAULT_ID) {
        const matchesSede = !!campus.filter(({ id }) => filterSede === id).length
        matchingFilters.push(matchesSede)
      }

      const filterResult = matchingFilters.reduce((accum, current) => accum && current, true);

      return filterResult
    })

    setFilteredUsers(afterFiltering)
    setPage(0)
  }

  return (
    <div className={userPage}>
      <Snackbar isOpen={!!error} handleClose={handleCloseError} content={error} duration={1000} severity="error" />
      <div>
        <Button variant="contained" href="/createUser" className={newUserButton}>
          Nuevo Usuario
        </Button>
        <Button variant="contained" href="/createUsersBulk" className={newUserButton}>
          Nuevo Lote de Usuarios
        </Button>
      </div>
      <UsersFilter sedes={campus} roles={roles} submitFilters={handleFilter} />
      <UsersTable
        userCount={userList && userList.length}
        users={filteredUsers}
        loading={loading}
        handleDelete={handleUserDeletion}
        changeStatus={changeUserStatus}
        updateState={changeUserStatus}
        disableActions={loadingUsers}
        page={page}
        setPage={setPage}
      />
    </div>
  )
}

export default Users
